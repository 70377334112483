import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import withTranslate from '../../../common/components/with-translate/with-translate';
import { ArrowDown } from '../icons/arrow-down';
import ActionButton from '../action-button';
import styles from './desktop-select.scss';
import MoreButton from '../more-button';
import withSettingsColor from '../../hoc/with-settings-color';
import { APP_TEXT_COLOR_PATH } from '@wix/communities-forum-client-commons';
import { Themes } from '../../constants/themes';

export class DesktopSelect extends Component {
  state = {
    isOpen: false,
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.hideComponent);
  };

  showComponent = () => {
    this.setState({ isOpen: true });
  };

  hideComponent = () => {
    this.setState({ isOpen: false });
  };

  render = () => {
    const { t, options, onChange, value, preTextColor } = this.props;

    const openButton = (
      <div className={classNames(styles.openButton)}>
        <span style={{ color: preTextColor }}>{t('sorting.sort-by')}</span>&nbsp;
        <span>{`${options.find((o) => o.value === value).label}`}</span>
        <span className={classNames(styles.arrow, { [styles.arrowUp]: this.state.isOpen })}>
          <ArrowDown />
        </span>
      </div>
    );
    return (
      <div data-hook="sorting-select">
        <MoreButton
          ariaLabel={t('sorting.sort-by')}
          icon={openButton}
          className={classNames(styles.moreButton)}
          iconClassName={classNames('forum-icon-fill', 'button-hover-color', 'button-hover-fill')}
          onHide={this.hideComponent}
          onShow={this.showComponent}
          theme={Themes.BlackAndWhite}
        >
          {options.map((p) => (
            <ActionButton
              theme={Themes.BlackAndWhite}
              onClick={() => onChange(p.value)}
              key={p.value}
              dataHook={p.value}
              isSelected={p.value === value}
            >
              <div className={styles.actionText}>{p.label}</div>
            </ActionButton>
          ))}
        </MoreButton>
      </div>
    );
  };
}

DesktopSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  preTextColor: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default flowRight(
  withTranslate,
  withSettingsColor({
    propName: 'preTextColor',
    path: APP_TEXT_COLOR_PATH,
    alpha: 0.6,
    siteColorAlpha: 0.6,
    siteColorFallback: 'color-5',
    fallbackColor: '#2f2e2e',
  }),
)(DesktopSelect);
