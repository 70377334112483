import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';

import { FILTER_SHOW_ALL } from '../../constants/filtering';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import { isInfiniteScrollLayoutType } from '../../selectors/layout-selectors';
import { getCategoryFilter, getPostTypeFilter } from '../../selectors/filter-selectors';
import { getCategoryPageSorting, getPostSortOptions } from '../../selectors/sorting-selectors';
import { getRouteParams } from '../../../common/router/router-selectors';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getActiveSiteMemberId } from '../../containers/header-navigation';

const SortingSelectHeadless = ({
  fetchCategoryPosts,
  setPostsPageSorting,
  categoryId,
  siteMemberId,
  page,
  filter,
  postTypes,
  t,
  children,
  sort,
  getSortOptions,
}) => {
  const onChange = (value) => {
    setPostsPageSorting(value);

    fetchCategoryPosts({
      categoryId,
      siteMemberId,
      page,
      sort: value,
      postType: postTypes.length > 1 ? filter : FILTER_SHOW_ALL,
    });
  };

  return children({
    options: getSortOptions(t).map(({ text, value }) => ({ label: text, value })),
    value: sort,
    onChange,
  });
};

SortingSelectHeadless.propTypes = {
  t: PropTypes.func,
  onChange: PropTypes.func,
  fetchCategoryPosts: PropTypes.func,
  setPostsPageSorting: PropTypes.func,
  categoryId: PropTypes.string,
  page: PropTypes.number,
  sort: PropTypes.string,
  filter: PropTypes.string,
  postTypes: PropTypes.array,
  children: PropTypes.func,
  fetchFirstPageOnly: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const params = getRouteParams(state);
  const { categorySlug } = params;
  const page = parseInt(params.page, 10) || 1;
  const emptyCategory = { _id: null, postTypes: [] };
  const getRouteCategory = () => getCategoryBySlug(state, categorySlug);
  const getFilterCategory = () => getCategoryBySlug(state, getCategoryFilter(state));
  const category = getRouteCategory() || getFilterCategory() || emptyCategory;

  return {
    categoryId: category._id,
    page: ownProps.fetchFirstPageOnly || isInfiniteScrollLayoutType(state, host.style) ? 1 : page,
    filter: getPostTypeFilter(state),
    sort: getCategoryPageSorting(state, host.style, category._id),
    getSortOptions: (t) => getPostSortOptions(state, category._id, t),
    postTypes: category.postTypes,
    fetchCategoryPosts: actions.fetchCategoryPosts,
    setPostsPageSorting: actions.setPostsPageSorting,
    siteMemberId: getActiveSiteMemberId(state),
  };
};

export default flowRight(withTranslate, connect(mapRuntimeToProps))(SortingSelectHeadless);
