import React from 'react';
import classNames from 'classnames';
import styles from './post-list-controls.component.scss';
import { HorizontalSeparator, VerticalSeparator } from '../separator';
import { CategorySelectHeadless } from '../category-select';
import { SortingSelectCategoryPageDesktop } from '../sorting-select-category';
import { CategoryListActions } from '../../containers/category-actions';
import CreatePostButton from '../create-post-button/create-post-button';
import { PostTypes } from '@wix/communities-forum-client-commons';
import { Category } from '../../types';
import { PostBreakpoints } from '../responsive-listener';
import { Themes } from '../../constants/themes';

type PostListControlsProps = {
  className?: string;
  dataHook?: string;
  isTop: boolean;
  showCategoryFilter: boolean;
  breakpointValue?: PostBreakpoints;
  isAuthenticated: boolean;
  showCreatePostAction: boolean;
  postTypes: PostTypes[];
  customCtaLabel: string;
  category: Category;
  renderPagination?: (isTop: boolean) => React.ReactElement;
};

const ListControls: React.FC<PostListControlsProps> = ({
  className,
  dataHook,
  isTop,
  breakpointValue,
  showCategoryFilter,
  category,
  showCreatePostAction,
  postTypes,
  customCtaLabel,
  renderPagination,
}) => {
  const isCategoryNameVisible = isTop && !showCategoryFilter && !!category?._id;
  const isCategorySelectVisible = isTop && showCategoryFilter;
  const isSortingVisible = isTop;
  const isCategoryActionsVisible = !showCategoryFilter && !!category?._id;

  return (
    <div
      className={classNames(
        styles.controls,
        {
          [styles.controlsWrapMd]: breakpointValue === PostBreakpoints.md,
          [styles.controlsWrapSm]: breakpointValue === PostBreakpoints.sm,
          [styles.controlsWrapXs]: breakpointValue === PostBreakpoints.xs,
        },
        className,
      )}
      data-hook={dataHook}
    >
      {!isTop && renderPagination?.(false)}
      {(isCategoryNameVisible || isCategorySelectVisible) && (
        <div className={styles.controlsLeft}>
          {isCategoryNameVisible && <div className={styles.nameContainer}>{category.label}</div>}
          {isCategorySelectVisible && (
            <div className={styles.categorySelectContainer}>
              <CategorySelectHeadless />
            </div>
          )}
          {isCategoryNameVisible && (isCategorySelectVisible || isSortingVisible) && (
            <VerticalSeparator className={styles.controlsSeparator} />
          )}
        </div>
      )}
      {isSortingVisible && (
        <div className={styles.sortSelectContainer}>
          <SortingSelectCategoryPageDesktop />
        </div>
      )}
      <HorizontalSeparator className={styles.horizontalSeparator} theme={undefined} />
      <div className={styles.controlsRight}>
        <CategoryListActions
          showCategoryActions={isCategoryActionsVisible}
          showMarkPostsAsRead
          category={category}
          theme={Themes.BlackAndWhite}
        />
        {showCreatePostAction && (
          <CreatePostButton
            categorySlug={category.slug}
            postTypes={postTypes}
            createPostCtaLabel={customCtaLabel}
          />
        )}
      </div>
    </div>
  );
};

export const PostListControls = ListControls;
