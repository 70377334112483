import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import { getCategoryFilter } from '../../selectors/filter-selectors';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { createFeedTypeQueryParam, getFeedType } from '../../containers/header-navigation';
import { default as CategorySelect } from './category-select';

class SortingSelectHeadless extends Component {
  onChange = (value) => {
    const { setCategoryFilter, navigateWithinForum, feedType, startLoadingCategory } = this.props;
    // Assuming category will be fetched when filter is set
    startLoadingCategory(value);
    setCategoryFilter(value);

    // After we set category filter, we navigate to same page to reset pagination handler
    const queryParams = feedType ? `?${createFeedTypeQueryParam(feedType)}` : '';
    const path = `${queryParams}`;

    navigateWithinForum(path);
  };

  render() {
    const { categorySlug } = this.props;

    return (
      <CategorySelect
        onChange={this.onChange}
        params={{ categorySlug }}
        isHeadless={true}
        label="filter-categories.label"
      />
    );
  }
}

SortingSelectHeadless.propTypes = {
  setCategoryFilter: PropTypes.func,
  categorySlug: PropTypes.string,
  feedType: PropTypes.string,
  navigateWithinForum: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const categorySlug = getCategoryFilter(state);
  const getCategory = (categorySlug) => getCategoryBySlug(state, categorySlug) || { _id: null };
  return {
    setCategoryFilter: actions.setCategoryFilter,
    categorySlug,
    navigateWithinForum: actions.navigateWithinForum,
    feedType: getFeedType(state),
    startLoadingCategory: (categorySlug) =>
      actions.startLoadingCategory(getCategory(categorySlug)._id),
  };
};

export default flowRight(withTranslate, connect(mapRuntimeToProps))(SortingSelectHeadless);
