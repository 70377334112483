import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import ActionButton from '../action-button';
import MoreButton from '../more-button';
import { ArrowDown } from '../icons/arrow-down';
import { getAllCategories } from '../../../common/selectors/categories-selectors';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './category-select.scss';
import {
  isPrivate,
  SORT_BY_NEWEST,
  APP_TEXT_COLOR_PATH,
} from '@wix/communities-forum-client-commons';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import { CurrencySignIcon } from '../icons/currency-sign-icon';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';
import NewContentIndicator from '../new-content-indicator';
import { Themes } from '../../constants/themes';

import withSettingsColor from '../../hoc/with-settings-color';

const ALL_POSTS_CATEGORY_SLUG = '';

const Pipe = <span className={classNames(styles.pipe, 'forum-card-border-color')} />;

class CategorySelect extends Component {
  state = {
    isActionsVisible: false,
  };

  handleCategoryChange = (categorySlug) => {
    if (this.props.onChange) {
      this.props.onChange(categorySlug);
      return;
    }

    this.props.redirectToCategory(categorySlug);
  };

  handlePostFetch = (category) => {
    if (this.props.onChange) {
      return;
    }

    this.props.resetPostTypeFilter();
    this.props.fetchCategoryPosts({ categoryId: category._id, page: 1 });
  };

  handlePostFetchNewest = (category) => {
    if (this.props.onChange) {
      return;
    }

    this.props.setPostsPageSorting(SORT_BY_NEWEST);
    this.props.resetPostTypeFilter();
    this.props.fetchCategoryPosts({ categoryId: category._id, page: 1, sort: SORT_BY_NEWEST });
  };

  onActionsShow = () => this.setState({ isActionsVisible: true });
  onActionsHide = () => this.setState({ isActionsVisible: false });

  renderArrow = () => {
    const className = this.state.isActionsVisible ? styles.arrowUp : undefined;
    return (
      <span className={classNames(styles.arrow, className)}>
        <ArrowDown />
      </span>
    );
  };

  render() {
    const {
      t,
      categories,
      categorySlug,
      contentFontClassName,
      state,
      preTextColor,
      label,
      isHeadless,
    } = this.props;
    let totalUnseenPostsCount = 0;
    const categoryOptions = categories.map((category) => {
      totalUnseenPostsCount += category.unseenPostsCount || 0;
      return {
        label: category.label,
        value: category.slug,
        isIndented: !!category.parentId,
        isPrivate: isPrivate(category),
        icon: hasCategoryPaidPlans(state, category.groups) ? (
          <CurrencySignIcon className={classNames('forum-icon-fill', styles.icon)} />
        ) : (
          <LockEmptyIcon className={classNames('forum-icon-fill')} />
        ),
        _id: category._id,
        unseenPostsCount: category.unseenPostsCount,
      };
    });
    const allPostsCategory = {
      label: t('category-select.all-posts'),
      value: ALL_POSTS_CATEGORY_SLUG,
      isIndented: false,
      _id: null,
      unseenPostsCount: totalUnseenPostsCount,
    };
    const options = [allPostsCategory, ...categoryOptions];
    const selectedCategory = options.find((c) => c.value === categorySlug);

    const current = (
      <div className={styles.selectedCategory}>
        {label && <span style={{ color: preTextColor }}>{t(label)}&nbsp;</span>}
        <span className={styles.selectedCategoryLabel}>
          {selectedCategory ? selectedCategory.label : ''}
        </span>
        {this.renderArrow()}
      </div>
    );
    const className = classNames(
      { 'breadcrumbs-text-color': !isHeadless },
      contentFontClassName,
      styles.moreButton,
    );

    return (
      <div data-hook="category-select">
        <MoreButton
          theme={Themes.BlackAndWhite}
          icon={current}
          position="right"
          className={className}
          iconClassName={classNames(
            { 'breadcrumbs-icon-fill': !isHeadless },
            { 'forum-icon-fill': isHeadless },
            'button-hover-color',
            'button-hover-fill',
            { 'post-header-text-color': !isHeadless },
          )}
          onHide={this.onActionsHide}
          onShow={this.onActionsShow}
          fixedHeight={true}
        >
          {options.map((o) => {
            const isAllCategoriesButton = o.value === ALL_POSTS_CATEGORY_SLUG;

            return (
              <>
                <ActionButton
                  theme={Themes.BlackAndWhite}
                  key={o.value}
                  onClick={() => this.handleCategoryChange(o.value)}
                  onMouseDown={() => this.handlePostFetch(o)}
                  dataHook={`category-select-action__${o.value}`}
                  isSelected={o.value === categorySlug}
                >
                  <div className={styles.actionTextWrapper}>
                    <div className={styles.actionTextWrapperInnerWrapper}>
                      <div className={styles.actionText}>
                        {o.isIndented && Pipe}
                        {o.label}
                      </div>
                      {o.isPrivate && o.icon}
                    </div>
                    <NewContentIndicator
                      onMouseDown={(ev) => {
                        ev.stopPropagation();
                        this.handlePostFetchNewest(o);
                      }}
                      count={o.unseenPostsCount}
                    />
                  </div>
                </ActionButton>
                {isAllCategoriesButton && (
                  <div className={classNames(styles.divider, 'forum-card-border-color')} />
                )}
              </>
            );
          })}
        </MoreButton>
      </div>
    );
  }
}

CategorySelect.propTypes = {
  t: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  categorySlug: PropTypes.string.isRequired,
  redirectToCategory: PropTypes.func,
  fetchCategoryPosts: PropTypes.func,
  resetPostTypeFilter: PropTypes.func,
  setPostsPageSorting: PropTypes.func,
  contentFontClassName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isHeadless: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  state,
  categories: getAllCategories(state),
  categorySlug: ownProps.params.categorySlug || ALL_POSTS_CATEGORY_SLUG,
  redirectToCategory: (categorySlug) =>
    actions.navigateWithinForum(categorySlug === ALL_POSTS_CATEGORY_SLUG ? '' : `/${categorySlug}`),
  fetchCategoryPosts: actions.fetchCategoryPosts,
  resetPostTypeFilter: actions.resetPostTypeFilter,
  setPostsPageSorting: actions.setPostsPageSorting,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withSettingsColor({
    propName: 'preTextColor',
    path: APP_TEXT_COLOR_PATH,
    alpha: 0.6,
    siteColorAlpha: 0.6,
    siteColorFallback: 'color-5',
    fallbackColor: '#2f2e2e',
  }),
)(CategorySelect);
